/**
 * function to check whether a JSON string is valid or not
 * @param jsonString a stringified json string
 * @returns boolean whether it's a valid JSON or not
 */
export const isValidJsonString = (jsonString: string): boolean => {
  if (!(jsonString && typeof jsonString === 'string')) {
    return false;
  }
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
};
