import { useReportWebVitals } from 'next/web-vitals';

// Helper function to get debug info for a metrics entry are based on the following code:
// https://github.com/GoogleChromeLabs/web-vitals-codelab/blob/main/web-vitals-google-analytics-bigquery/diagnostics.html
// In theory NextJS should add an attributen property (via webVitalsAttributen array in the next.config) to the metrics entry, but it doesn't.
// Until this is fixed, we have to use the following helper function to get the debug info.

const getSelector = (node: HTMLElement) => {
  if (!node || !node.nodeName) {
    return '';
  }

  let selector = node.nodeName.toLowerCase();
  selector = `${selector} (${node.classList.value})`;

  return selector;
};

const getCLSDebugTarget = (entries: any[]) => {
  const largestEntry = entries.reduce((a, b) => (a && a.value > b.value ? a : b));
  if (largestEntry?.sources?.length) {
    const largestSource = largestEntry.sources.reduce((a: any, b: any) => {
      const area = (el: any) => el.previousRect.width * el.previousRect.height;
      return a.node && area(a) > area(b) ? a : b;
    });
    if (largestSource) {
      return largestSource.node;
    }
  }
};

const getDebugInfo = (name: string, entries: any[] = []) => {
  if (entries.length) {
    if (name === 'LCP') {
      const lastEntry = entries[entries.length - 1];
      return {
        debug_target: getSelector(lastEntry.element),
      };
    }

    if (name === 'FID') {
      const firstEntry = entries[0];
      return {
        debug_target: getSelector(firstEntry.target),
      };
    }

    if (name === 'CLS') {
      const target = getCLSDebugTarget(entries);
      return {
        debug_target: getSelector(target),
      };
    }
  }

  return {
    debug_target: '(not set)',
  };
};

const useWebvitals = () => {
  useReportWebVitals((metric) => {
    if (window && (window as any).gtag) {
      const eventParams = {
        value: metric.delta,
        metric_id: metric.id,
        metric_value: metric.value,
        metric_delta: metric.delta,
        debug_info: getDebugInfo(metric.name, metric.entries),
        non_interaction: true,
      };

      (window as any).gtag('event', metric.name, eventParams);
    }
  });
};

export default useWebvitals;
